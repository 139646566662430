import { useEffect } from 'react';

import localFont from '@next/font/local';
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import { PageProps } from '@hultafors/solidgear/types';

const ApplicationProvider = dynamic(() =>
  import('@hultafors/solidgear/context').then(
    (module) => module.ApplicationContextProvider,
  ),
);

const Footer = dynamic(() =>
  import('@hultafors/solidgear/components').then((module) => module.Footer),
);
const GlobalStyle = dynamic(() =>
  import('@hultafors/solidgear/components').then((module) => module.GlobalStyle),
);
const MaintenanceBlock = dynamic(() =>
  import('@hultafors/solidgear/components').then(
    (module) => module.MaintenanceBlock,
  ),
);
const Nav = dynamic(() =>
  import('@hultafors/solidgear/components').then((module) => module.Nav),
);

export { reportWebVitals } from '@hultafors/shared/helpers';

const fontLeagueGothic = localFont({
  src: '../fonts/LeagueGothic-Regular.woff2',
  variable: '--font-league-gothic',
});

const fontHelveticaNeue = localFont({
  src: '../fonts/HelveticaNeueLTPro-Roman.woff2',
  variable: '--font-helvetica-neue',
});

const HelveticaNeueBold = localFont({
  src: '../fonts/HelveticaNeueLTPro-Md.woff2',
  variable: '--font-helvetica-neue-bold',
});

const CustomApp = ({ Component, pageProps, router }: AppProps<PageProps>) => {
  const {
    global,
    footer,
    allMenuTrees,
    maintenanceMode,
    settings,
    featureToggle,
    ...restProps
  } = pageProps;

  const onRouteChangeComplete = (page: string) => {
    sendGTMEvent({ event: 'pageview', page });
  };

  useEffect(() => {
    if (router.events) {
      onRouteChangeComplete('/');
      router.events.on('routeChangeComplete', onRouteChangeComplete);
    }
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  const fonts = [
    fontLeagueGothic.variable,
    fontHelveticaNeue.variable,
    HelveticaNeueBold.variable,
  ].join(' ');

  return (
    <>
      <style jsx global>
        {`
          html,
          input,
          textarea,
          button,
          select {
            font-family: ${fontHelveticaNeue.style.fontFamily};
          }
        `}
      </style>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <GlobalStyle
        isLanguageSelectorVisible={
          !!settings?.languages?.length && settings.languages.length > 1
        }
      />

      <ApplicationProvider
        value={{
          settings,
          global,
          footer,
          allMenuTrees,
          maintenanceMode,
          featureToggle,
        }}
      >
        <div className={fonts}>
          <Nav />
          {maintenanceMode && (
            <MaintenanceBlock error={false} maintenanceData={maintenanceMode} />
          )}
          <Component {...restProps} settings={settings} />
          <GoogleTagManager gtmId="GTM-5GDPS8B" />
          <Footer />
          <div id="portal" />
        </div>
      </ApplicationProvider>
    </>
  );
};

export default CustomApp;
